// General

.spinner {
  position:fixed;
  top:30%;
  left:calc(50% - 25px);
  margin: 100px auto;
  width: 100px;
  height: 50px;
  text-align: center;
  font-size: 10px;
}



.spinner > div {
  background-color: white;
  height: 100%;
  width: 3px;
  margin-left:3px;
  margin-right:3px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

html,
body {
  overflow-x: hidden !important;
}
body {
  position: relative !important;

  @media only screen and (max-width: 1164) {
    font-size: 0.6rem !important;
  }
}

.table-responsive {
  @media only screen and (max-width: 468px) {
    font-size: 6pt !important;
  }
}
.table {
  @media only screen and (max-width: 468px) {
    font-size: 6pt !important;
  }
}

.mobile-buy {
  right:300px;
  width:170px;
  @media only screen and (max-width: 991px) {
    font-size:9px;
    width:100px;
    right: 0px;
  }
}

.mobile-hidden {
  @media only screen and (max-width: 991px) {
    display: none !important;
  }
}

.smartphone-hidden {
  @media only screen and (max-width: 468px) {
    display: none !important;
  }
}

.desktop-hidden {
  @media only screen and (min-width: 992px) {
    display: none !important;
  }
}

/*-----------------------------
            NAVBAR
-----------------------------*/

//Search hidden within nav on mobile devices (instead displayed above table)
// #search {
//   @media only screen and (max-width: 991px) {
//     display: none;
//   }
// }

.nav-wrapper {
  max-width: 1500px !important;
  line-height: 60px !important;
  display: inline-block !important;
}

.multiverse-logo {
  height: 30px !important;
}

/*-----------------------------
            Blocks Table
-----------------------------*/

html
  body
  div#app
  div
  div.container-fluid
  div.container
  div.d-flex.flex-wrap.flex-row
  div.col-sm-12.col-md-9
  div
  table.table.table-striped.table-sm.table-hover
  thead.thead-light
  tr
  th {
  font-weight: 300 !important;
}

html
  body
  div#app
  div
  div.container-fluid
  div.container
  div.d-flex.flex-wrap.flex-row
  div.col-sm-12.col-md-3
  div
  table.table.table-sm.table-hover
  thead.thead-light
  tr
  th {
  font-weight: 300 !important;
}

div#app table.table {
  @media only screen and (max-width: 991px) {
    font-size: 8pt !important;
  }

  @media only screen and (max-width: 460px) {
    font-size: 6pt !important;
  }
}

html
  body
  div#app
  div
  div.container-fluid
  div.container
  div.d-flex.flex-wrap.flex-row
  div.container.center
  div.d-flex.flex-wrap.flex-row
  div.col-md-9.noPadding
  div.table-responsive
  table.table.table-sm.table-hover {
  @media only screen and (max-width: 460px) {
    font-size: 6pt !important;
  }
}

div#app table.table {
  @media only screen and (max-width: 991px) {
    font-size: 8pt !important;
  }
}

.pagination-buttons {
  float: right !important;

  @media only screen and (max-width: 991px) {
    float: none !important;
  }
}

.noPadding {
  @media only screen and (max-width: 750px) {
    padding: 0 !important;
  }
}

html
  body
  div#app
  div
  div.container-fluid
  div.container
  div.d-flex.flex-wrap.flex-row
  div.col-sm-12.col-md-9.noPadding
  div.d-flex.flex-wrap.flex-row
  div.col-xs-4.noPadding.desktop-hidden
  nav.pagination-buttons
  ul.pagination.justify-content {
  margin-top: 10px !important;
}

html
  body
  div#app
  div
  div.container-fluid
  div.container
  div.d-flex.flex-wrap.flex-row
  div.col-sm-12.col-md-3.noPadding {
  @media only screen and (max-width: 991px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.searchForm {
  @media only screen and (min-width: 596px) {
    padding-left: 50px !important;
  }
  @media only screen and (max-width: 599px) {
    margin-bottom: 25px !important;
  }
}

/*======================================================
                   Footer Section
  ======================================================*/

.footer {
  background: #000000;
  color: #fff;
  padding: 88px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

@media only screen and (max-device-width: 640px) {
  .footer {
    padding: 74px 0;
  }
}
.footer-grid {
  width: 80%;
  display: grid;
  margin: 0 auto;
  padding: 0 24px;
  padding-top: 100px;
  padding-bottom: 15px;
  flex: 1 1;
  grid-template-columns: -webkit-min-content minmax(20px, 156px) repeat(
      4,
      -webkit-min-content minmax(20px, 120px)
    );
  grid-template-columns: min-content minmax(20px, 156px) repeat(
      4,
      min-content minmax(20px, 120px)
    );
  grid-row-gap: 56px;
  //   background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  color: #ffffff;
  max-width: 1164px;
}

@media only screen and (max-device-width: 640px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 48px;
  }
}

@media only screen and (max-device-width: 640px) {
  .footer-logo {
    grid-column: 1/3;
    max-width: 175px;
    height: auto;
  }
  .footer-gap {
    display: none;
  }
}
.footer-title {
  font-weight: 700;
  margin-bottom: 18px;
}

@media only screen and (max-device-width: 640px) {
  .footer-title {
    margin-bottom: 20px;
  }
}
.footer-link {
  text-decoration: none;
  color: inherit;
  display: block;
  margin: 12px 0;
}

@media only screen and (max-device-width: 640px) {
  .footer-link {
    margin: 16px 0;
  }
}
.footer-link:last-child {
  margin-bottom: 0;
}
.footer-link:focus,
.footer-link:hover {
  opacity: 0.7;
  outline: none;
}
.footer-copyright {
  font-size: 14px;
  letter-spacing: -0.01px;
  line-height: 18px;
  color: #999999;
  grid-column: 3/-1;
  grid-row: 2;
}

@media only screen and (max-device-width: 640px) {
  .footer-copyright {
    grid-column: auto;
    grid-row: auto;
  }
}

.pre-footer {
  //   background: #000000;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  color: #fff;
  padding: 90px 24px 40px;
  justify-content: center;
  border-bottom: 1px solid #333333;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer {
    padding: 40px 24px;
  }
}
.pre-footer-grid {
  display: flex;
  max-width: 1164px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-grid {
    display: block;
    text-align: center;
    width: 90%;
  }
}

.pre-footer-tagline {
  font-size: 36px;
  letter-spacing: -0.04px;
  line-height: 42px;
  font-weight: 700;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-tagline {
    font-size: 32px;
    letter-spacing: -0.36px;
    line-height: 38px;
  }
}
.pre-footer-subheading {
  font-size: 20px;
  letter-spacing: -0.01px;
  line-height: 28px;
  margin: 24px 0 48px;
  width: 70%;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-subheading {
    font-size: 16px;
    letter-spacing: -0.01px;
    line-height: 22px;
    width: 90%;
    margin: 10px auto;
  }
}
.pre-footer-buttons {
  text-align: right;
  padding-top: 2em;
  width: 70%;
  margin: auto;
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-buttons {
    text-align: center;
    padding-top: 0.5em;
  }
}
.pre-footer-buttons a {
}

@media only screen and (max-device-width: 640px) {
  .pre-footer-buttons a {
    margin-left: 0.5rem;
  }
}

.main-footer {
  //   background-color: #000000;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}
.footer-btn {
  margin-bottom: 20px;
  margin-right: 20px;
}
